import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import "./Landing.scss";
import SampleComic1 from '../img/sample-comic-1.png';
import SampleComic2 from '../img/sample-comic-2.png';
import SampleComic3 from '../img/sample-comic-3.png';
import Tag from '../img/tag.png';
import rfid from '../img/icons/rfid.svg';
import qr from '../img/icons/qr.svg';
import waternark from '../img/icons/watermark.svg';
import nft from '../img/icons/nft.svg';
import Modal from './Modal';

const AuthorityFor = ["collectibles", "exclusives", "authentication"];

type LandingState = {
    certificateNumber: string;
    authorityForIndex: number;
    currentlyScanning: boolean;
    scannerError?: boolean;
    showRFIDInfoPopup: boolean;
}

export default class Landing extends React.Component<RouteComponentProps<{}>, LandingState> {
    state: LandingState = {
        certificateNumber: "",
        authorityForIndex: 0,
        currentlyScanning: false,
        showRFIDInfoPopup: false
    };

    private changeAuthority() {
        this.setState(state => ({ authorityForIndex: (state.authorityForIndex + 1) % AuthorityFor.length }));
    }

    render() {
        const desclaimer1 = "Our Hard Slab™ is the world’s first comic slab with a fully welded perimeter for extreme durability.  Made of a scratch-resistant, transparent polystyrene material, it features a stackable design that prevents surfaces from touching.  The streamlined slab design is smaller than typical slabs making so they are easier to carry and store.";
        const title1 = "Hard Slab™";
        const desclaimer2 = "Our Soft Slab™ features separate compartments for the tag and the comic.  The durable PET-G enclosure is narrow enough to fit in comic long-boxes while being completely transparent on both the front and the back – providing an advantage over backers and boards.  The Soft Slab can be easily opened with scissors or ultrasonically sealed into a Hard Slab for permanent protection.";
        const title2 = "Soft Slab™";
        const securityDetails = [
            { url: rfid, description: "Embedded RFID security chip can be scanned by most modern smartphones." },
            { url: qr, description: "QR code gives instant access to collectible details including full pictures of the front and back of the comic." },
            { url: waternark, description: "Unique watermark is visible in photos, helping you to easily confirm the authenticity of each tag." },
            { url: nft, description: "Each tag is linked to a token for that collectible." },
        ];

        return <div className="landing-page">
            {this.state.showRFIDInfoPopup && <Modal
                className="rfid-info"
                title="rfid tag scanning"
                onClose={() => this.setState({ showRFIDInfoPopup: false })}
                buttons={[<button className="btn btn-outline-primary" onClick={() => this.setState({ showRFIDInfoPopup: false })}>close</button>]}
            >
                <p>Compatible Android phones can scan the RFID tag embedded in your collectible.</p>
                <p> If your NFC-capable Android phone is running Chrome v89 or newer, Opera v63 or newer, or Samsung Internet v15.0 or newer, RFID lookup should be available to you.</p>
                <p>Simply tap on the RFID logo <i className="ud ud-authority-rfid" onClick={_ => this.canScanRFID && this.setState({ showRFIDInfoPopup: false }, () => this.scanForRFID())} /> and hold your phone's NFC antenna next to the RFID tag behind the QR code on your collectible to scan the tag and retrieve the item page for your collectible.</p>
            </Modal>}
            <div className="page-section we-are-authority">
                <div className="comics">
                    <img src={SampleComic3} className="sample-comic3" />
                    <img src={SampleComic2} className="sample-comic2" />
                    <img src={SampleComic1} className="sample-comic1" />
                </div>
                <div className="authority-section">
                    <div className="the-authority">
                        <div className="text-style">we are</div>
                        <div className="authority">THE AUTHORITY</div>
                        <div onClick={_ => this.changeAuthority()} className="text-style">for {AuthorityFor[this.state.authorityForIndex]}</div>
                    </div>
                    <p>
                        Authenticate and explore comics, figurines, and more.  Enter the identification number of your collectible to get started.
                    </p>

                    <div className="verify-section">
                        <div className="text-header" >verify a certificate </div>
                        {this.certificateLookupForm()}
                    </div>
                </div>
            </div>
            <div className="page-section protect-collectibles">
                <h3>Protect Your Collectibles</h3>
                <div className="card-container">
                    {this.getCollectibles(SampleComic1, title1, desclaimer1)}
                    {this.getCollectibles(SampleComic2, title2, desclaimer2)}
                </div>
            </div>
            <div className="page-section security-tag">
                <h3 className="text-black"> comic book security and identification tag </h3>
                <div className="row">
                    <div className="security-img col-12 col-md-auto">
                        <img src={Tag} className="sample-comic1" />
                    </div>
                    <div className="col row mx-0">
                        {securityDetails.map(m => this.getSecurityTagDetails(m.url, m.description))}
                    </div>
                </div>
            </div>
            <div className="page-section verify-certificate">
                <h3>Verify a Certificate</h3>
                <p>
                    To authenticate a collectible, enter in the identification number located on the right edge of your collectible’s tag.
                </p>
                {this.certificateLookupForm()}
            </div>
        </div>;
    }

    private get canScanRFID() {
        return typeof NDEFReader === "function";
    }

    private reader: NDEFReader | undefined = this.canScanRFID ? new NDEFReader() : undefined;
    private cancelScan?: AbortController;

    private scanForRFID() {
        if (this.state.currentlyScanning) {
            this.cancelScan && this.cancelScan.abort();
            return this.setState({ currentlyScanning: false });
        }

        if (!this.reader)
            return;

        this.reader.onreading = this.reader.onreading || (data => this.setState({
            currentlyScanning: false,
            scannerError: false,
            certificateNumber: ((data || {}).serialNumber || "").split(":").reverse().join('').toLocaleUpperCase()
        }, () => this.navigateToCertificatePage()));

        this.reader.onreadingerror = this.reader.onreadingerror || (_ => this.setState({ currentlyScanning: false, scannerError: true },
            () => setTimeout(() => this.setState({ scannerError: false }), 2500)));

        this.cancelScan = new AbortController();
        this.setState({ currentlyScanning: true }, () => this.reader && this.cancelScan && this.reader.scan({ signal: this.cancelScan.signal }));
    }

    private navigateToCertificatePage() {
        if (this.state.certificateNumber.length > 0 && !this.state.currentlyScanning && !this.state.scannerError)
            this.props.history.push(`/${encodeURI(this.state.certificateNumber)}`);
    }

    private certificateLookupForm() {
        const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
            e.preventDefault();
            this.navigateToCertificatePage();
            return false;
        }
        return <form onSubmit={onSubmit}>
            <div className="input-box">
                {/*<i className="ud ud-authority-tag" style={{ alignSelf: "center" }} />*/}
                <input type="text" placeholder={this.state.currentlyScanning ? "awaiting scan..." : this.state.scannerError ? "scan failed" : "enter a certificate number"} value={(this.state.currentlyScanning || this.state.scannerError) ? "" : this.state.certificateNumber} onChange={e => this.setState({ certificateNumber: e.currentTarget.value })} />
                <button role="submit">go</button>
                <button role="button" className="btn-rfid-scan" disabled={!this.canScanRFID} onClick={e => { e.preventDefault(); this.scanForRFID(); return false; }}>
                    {this.state.currentlyScanning ? <i className="ud ud-authority-loading fa fa-spin" /> : this.state.scannerError ? <i className="ud ud-x-sharp text-alert" /> : <i className="ud ud-authority-rfid" />}
                </button>
                <button role="button" onClick={e => { e.preventDefault(); this.setState({ showRFIDInfoPopup: true }); return false; }}>
                    <i className="ud ud-action-info" />
                </button>
            </div>
        </form>;
    }

    private getCollectibles(url: string, title: string, desclaimer: string) {
        return <div className="card">
            <div className="card-body">
                <div className="img-container">
                    <img src={url} className="sample-comic" />
                </div>
            </div>
            <div className="card-footer">
                <div className="text-header">{title}</div>
                <p>{desclaimer}</p>
            </div>
        </div>;
    }
    private getSecurityTagDetails(url: string, text: string) {
        return <div className="row col-lg-3 flex-md-column mx-4 mx-lg-0 px-0 px-lg-3 mt-5 security-tag-details" key={url}>
            <div className="col-lg-auto col-3 pl-0 px-lg-0"><img src={url} /></div>
            <div className="col-lg col-9 pr-0 px-lg-0">{text}</div>
        </div>;
    }
}